import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ProductCard from './ProductCard'; 
import './Mylikes.css';
import MessageModal from './MessageModal'; // Import the modal component

// Create an Axios client instance configured for API calls and CSRF handling
const client = axios.create({
  baseURL: "https://www.refeiscollective.com",
  withCredentials: true,
});

// Intercept every request to include the CSRF token
client.interceptors.request.use(function (config) {
  const token = document.cookie.split('; ').find(row => row.startsWith('csrftoken='))
      ?.split('=')[1];
  if (token) {
      config.headers['X-CSRFToken'] = token;
  }
  return config;
});

function MyLikes() {
  const [likes, setLikes] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  useEffect(() => {
    fetchLikes();
  }, []);

  const fetchLikes = async () => {
    try {
      const response = await client.get('/api/likes/');
      setLikes(response.data);  // Assume the API returns an array of likes
    } catch (error) {
      setModalMessage('Failed to load likes.');
      setIsModalOpen(true);
    }
  };

  const handleUnlike = async (likeId) => {
    try {
      const response = await client.delete(`/api/likes/remove/${likeId}/`);
      if (response.status === 204) {
        // Use functional update to ensure we are not capturing stale state
        setLikes(currentLikes => currentLikes.filter(like => like.id !== likeId));
        setModalMessage('Like removed successfully.');
        setIsModalOpen(true);
      }
    } catch (error) {
      console.error('Failed to unlike product', error);
      setModalMessage('Failed to remove like.');
      setIsModalOpen(true);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="my-likes">
      <h1>My Likes</h1>
      <div className="product-cards-container">
        {likes.map(like => (
          <ProductCard
            key={like.id}
            product={like.product}
            images={like.uploaded_images}
            showUnlikeButton={true}
            unlike={() => handleUnlike(like.id)}
          />
        ))}
      </div>
      <MessageModal message={modalMessage} isOpen={isModalOpen} onClose={closeModal} />
    </div>
  );
}

export default MyLikes;
















