import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';  // Import useNavigate
import './RegisterPage.css'; 


axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = true;

const client = axios.create({
  baseURL: "https://www.refeiscollective.com",
});

function Register({ onRegister }) {
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();  
  const [showPassword, setShowPassword] = useState(false);
  const [isUsernameBad, setIsUsernameBad] = useState(false);
  const [isPasswordBad, setIsPasswordBad] = useState(false);
  const badWords = ["fuck", "bitch", "shit", "ass","hell","fag","faggot","nigga","nigger","dick","pussy","damn","fugly","poop","dike","arse","asshole",
                    "tity","slut","whore","titties","slutty","hooker","cunt","bullshit","crap","bastard","nigro","nigra","niggas","jesus christ","balls",
                    "cum","piss","niggaz","nig","jizz","skank","dildo","dildos","niglet","penis","vagina","chink","ching chong","ape","anus","rape",
                    "sex","sexual","sexy","fat","fetish","gay","lesbian","homo","homosexual","homosexuality","homosex","obey","sexism","sexist",
                    "obese","ugly","ugliest","uglyest","uglyass","uglyasses","uglyboi","uglyboy","uglyboys","uglygirl","uglygirls","uglygirly","blowjob",
                    "porn","semen","kill","murder","piss","sexualintercourse","bimbo","crack","cocaine","drugs","pornography","weed","drunk","shoot",
                    "marijuana","suck","nuts","nutts","sucknut"
  ]; 

  function containsBadWord(input) {
    return badWords.some(badWord => input.toLowerCase().includes(badWord));
  }

  function handleUsernameChange(e) {
    const newUsername = e.target.value;
    if (!containsBadWord(newUsername)) {
      setUsername(newUsername);
      setIsUsernameBad(false);
    } else {
      setIsUsernameBad(true);
    }
  }

  function handlePasswordChange(e) {
    const newPassword = e.target.value;
    if (!containsBadWord(newPassword)) {
      setPassword(newPassword);
      setIsPasswordBad(false);
    } else {
      setIsPasswordBad(true);
    }
  }

  function submitRegistration(e) {
    e.preventDefault();
    client.post("/api/register", { email, username, password })
      .then(function(res) {
        onRegister(true);  // Optionally update app state or manage session
        navigate('/login');  // Navigate to login page after successful registration
      })
      .catch(function(error) {
        console.error('Registration failed:', error);
        // Optionally handle errors here (e.g., show an error message)
      });
  }

  return (
    <div className="register-container">
      <form className="register-form" onSubmit={submitRegistration}>
        <div className="form-group">
          <label>Email address</label>
          <input
            type="email"
            className="form-control"
            placeholder="Enter email"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Username</label>
          <input
            type="text"
            className={`form-control ${isUsernameBad ? 'bad-input' : ''}`}
            placeholder="Enter username"
            value={username}
            onChange={handleUsernameChange}
          />
        </div>
        <div className="form-group">
          <label>Password</label>
          <div className="password-container100">
            <input
              type={showPassword ? "text" : "password"}
              className={`form-control ${isPasswordBad ? 'bad-input' : ''}`}
              placeholder="Password"
              value={password}
              onChange={handlePasswordChange}
            />
            {password && (
              <small
                onClick={() => setShowPassword(!showPassword)}
                style={{ cursor: 'pointer' }}
                className="show-hide-password"
              >
                {showPassword ? "Hide" : "Show"}
              </small>
            )}
          </div>
          {isPasswordBad && <small className="error-message">Password contains inappropriate content.</small>}
          <small className="password-hint">Password should contain at least 8 characters</small>
        </div>
        <button className="submit-btn" type="submit">Register</button>
      </form>
      <div className="already-member-link">
        Already a member? <Link to="/login">Log in</Link>
      </div>
    </div>
  );
};

export default Register;
