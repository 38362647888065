import React, { useState } from 'react';
import axios from 'axios';
import './ProductUploadForm.css';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const client = axios.create({
  baseURL: "https://www.refeiscollective.com",
  withCredentials: true,
  headers: {
    'Content-Type': 'multipart/form-data'
  }
});

client.interceptors.request.use(function (config) {
  const token = document.cookie.split('; ').find(row => row.startsWith('csrftoken='))
      ?.split('=')[1];
  if (token) {
    config.headers['X-CSRFToken'] = token;
  }
  return config;
});

const MySwal = withReactContent(Swal);

const badWords = ["fuck", "bitch", "shit", "ass","hell","fag","faggot","nigga","nigger","dick","pussy","damn","fugly","poop","dike","arse","asshole",
"tity","slut","whore","titties","slutty","hooker","cunt","bullshit","crap","bastard","nigro","nigra","niggas","jesus christ","balls",
"cum","piss","niggaz","nig","jizz","skank","dildo","dildos","niglet","penis","vagina","chink","ching chong","ape","anus","rape",
"sex","sexual","sexy","fat","fetish","gay","lesbian","homo","homosexual","homosexuality","homosex","obey","sexism","sexist",
"obese","ugly","ugliest","uglyest","uglyass","uglyasses","uglyboi","uglyboy","uglyboys","uglygirl","uglygirls","uglygirly","blowjob",
"porn","semen","kill","murder","piss","sexualintercourse","bimbo","crack","cocaine","drugs","pornography","weed","drunk","shoot",
"marijuana","suck","nuts","nutts","sucknut"
]; 

function containsBadWord(input) {
  return badWords.some(badWord => input.toLowerCase().includes(badWord));
}

const ProductUploadForm = ({ isAuthenticated, onProductUpload }) => {
  const navigate = useNavigate();
  const [activeSection, setActiveSection] = useState('productDetails');
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    price: '',
    category: 'women',  // Default to 'men', can be empty if you prefer no default
    chest: '',
    fullLength: '',
    hips: '',
    shoulders: '',
    sleeveLength: '',
    waist: '',
    letout: '',
    brand: '',
    year:'',
    uploaded_images: [],  // Updated to match the backend serializer key
    uploaded_videos: []   // Add videos to the state
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'name' || name === 'description' || name === 'brand') {
      if (containsBadWord(value)) {
        MySwal.fire({
          icon: 'error',
          title: 'Invalid Input',
          text: 'Your input contains inappropriate content. Please remove it.',
        });
        return;
      }
    }
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData({ ...formData, [name]: [...files] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isAuthenticated) {
      MySwal.fire({
        icon: 'warning',
        title: 'Please log in',
        text: 'You need to log in to upload products.',
      }).then(() => {
        navigate('/login');
      });
      return;
    }

    const dataToSend = new FormData();
    Object.keys(formData).forEach(key => {
      if (key === 'uploaded_images' || key === 'uploaded_videos') {
        // Append each file to the FormData using the new key
        formData[key].forEach(file => {
          dataToSend.append(key, file);
        });
      } else {
        dataToSend.append(key, formData[key]);
      }
    });

    try {
      const response = await client.post('/api/products/', dataToSend);
      if (response.status === 201) {
        onProductUpload(response.data);
        MySwal.fire({
          icon: 'success',
          title: 'Product uploaded',
          text: 'Your product has been uploaded successfully!',
        }).then(() => {
          navigate('https://www.refeiscollective.com/');
        });
      }
    } catch (error) {
      console.error('Error uploading product:', error);
      MySwal.fire({
        icon: 'error',
        title: 'Upload failed',
        text: 'There was an error uploading the product. Please check the console for more details.',
      });
    }
  };

  if (!isAuthenticated) {
    return <div>Please log in to upload products.</div>;
  }

  return (
    <div className="upload-product">
      <div className="header-container">
        <div className="upload-form">
          <div className="form-navigation">
            <button onClick={() => setActiveSection('productDetails')}>Product Details</button>
            <button onClick={() => setActiveSection('measurements')}>Measurements</button>
            <button onClick={() => setActiveSection('media')}>Images/Videos</button>
          </div>

          <form onSubmit={handleSubmit}>
            {activeSection === 'productDetails' && (
              <div>
                <input type="text" name="name" placeholder="Name" value={formData.name} onChange={handleInputChange} />
                <textarea name="description" placeholder="Description" value={formData.description} onChange={handleInputChange}></textarea>
                <input type="number" name="price" placeholder="Price" value={formData.price} onChange={handleInputChange} />
                <select name="category" value={formData.category} onChange={handleInputChange}>
                  <option value="women">Women</option>
                  <option value="men">Men</option>
                </select>
                <input type="number" name="year" placeholder="Year" value={formData.year} onChange={handleInputChange} />
              </div>
            )}

            {activeSection === 'measurements' && (
              <div>
                <input type="number" name="chest" placeholder="Chest (in inches)" value={formData.chest} onChange={handleInputChange} />
                <input type="number" name="fullLength" placeholder="Full Length (in inches)" value={formData.fullLength} onChange={handleInputChange} />
                <input type="number" name="hips" placeholder="Hips (in inches)" value={formData.hips} onChange={handleInputChange} />
                <input type="number" name="shoulders" placeholder="Shoulders (in inches)" value={formData.shoulders} onChange={handleInputChange} />
                <input type="number" name="sleeveLength" placeholder="Sleeve Length (in inches)" value={formData.sleeveLength} onChange={handleInputChange} />
                <input type="number" name="waist" placeholder="Waist (in inches)" value={formData.waist} onChange={handleInputChange} />
                <input type="number" name="letout" placeholder="Let out (in inches)" value={formData.letout} onChange={handleInputChange} />
                <input type="text" name="brand" placeholder="Brand name" value={formData.brand} onChange={handleInputChange} />
              </div>
            )}

            {activeSection === 'media' && (
              <div>
                <input type="file" name="uploaded_images" multiple accept="image/*" onChange={handleFileChange} />
                <input type="file" name="uploaded_videos" multiple accept="video/*" onChange={handleFileChange} />
              </div>
            )}

            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ProductUploadForm;
