import React, { useState } from 'react';
import Swal from 'sweetalert2';
import './BidPopup.css';
import axios from 'axios';

const badWords = ["fuck", "bitch", "shit", "ass","hell","fag","faggot","nigga","nigger","dick","pussy","damn","fugly","poop","dike","arse","asshole",
"tity","slut","whore","titties","slutty","hooker","cunt","bullshit","crap","bastard","nigro","nigra","niggas","jesus christ","balls",
"cum","piss","niggaz","nig","jizz","skank","dildo","dildos","niglet","penis","vagina","chink","ching chong","ape","anus","rape",
"sex","sexual","sexy","fat","fetish","gay","lesbian","homo","homosexual","homosexuality","homosex","obey","sexism","sexist",
"obese","ugly","ugliest","uglyest","uglyass","uglyasses","uglyboi","uglyboy","uglyboys","uglygirl","uglygirls","uglygirly","blowjob",
"porn","semen","kill","murder","piss","sexualintercourse","bimbo","crack","cocaine","drugs","pornography","weed","drunk","shoot",
"marijuana","suck","nuts","nutts","sucknut"
]; 

function containsBadWord(input) {
  return badWords.some(badWord => input.toLowerCase().includes(badWord));
}

const client = axios.create({
    baseURL: "https://www.refeiscollective.com",
    withCredentials: true,
  });
  
  client.interceptors.request.use(function (config) {
    const token = document.cookie.split('; ').find(row => row.startsWith('csrftoken='))
      ?.split('=')[1];
    config.headers['X-CSRFToken'] = token;
    return config;
});

const BidPopup = ({ product, bidAmount, setBidAmount, bidComments, setBidComments, submitBid, closePopup }) => {
    const [closing, setClosing] = useState(false);
  
    const min_bid_amount = parseFloat(product.price) * 0.10;  // Ensure product.price is treated as a number
    const max_bid_amount = parseFloat(product.price);
  
    const handleCommentsChange = (e) => {
        const value = e.target.value;
        if (containsBadWord(value)) {
          Swal.fire({
            icon: 'error',
            title: 'Invalid Input',
            text: 'Your comments contain inappropriate content. Please remove it.',
          });
          return;
        }
        setBidComments(value);
    };
  
    const checkExistingBid = async () => {
      try {
        const response = await client.get(`/api/check_existing_bid/${product.id}/`);
        return response.data.exists;
      } catch (error) {
        console.error('Error checking existing bid:', error);
        return false;
      }
    };
  
    const handleSubmit = async () => {
      const hasExistingBid = await checkExistingBid();
  
      if (hasExistingBid) {
        Swal.fire({
          icon: 'error',
          title: 'Duplicate Bid',
          text: "You've already placed a bid on this product. You can update your bid in the active bids section",
        });
        return;
      }
  

      if (parseFloat(bidAmount) < min_bid_amount) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid Bid',
          text: `Bid amount cannot be less than $${min_bid_amount.toFixed(2)}.`,
        });
        return;
      }
  
      if (parseFloat(bidAmount) > max_bid_amount) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid Bid',
          text: `Bid amount cannot exceed $${max_bid_amount.toFixed(2)}.`,
        });
        return;
      }
  
      Swal.fire({
        title: 'Confirm Your Bid',
        text: `Send a bid of $${bidAmount} to ${product.name}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, submit bid!'
      }).then((result) => {
        if (result.isConfirmed) {
          submitBid()
            .then(response => {
              Swal.fire(
                'Submitted!',
                'Your bid has been submitted.',
                'success'
              );
              handleClose();
            })
            .catch(error => {
              const errorMessage = error.response?.data?.detail || 'Failed to submit your bid. Please try again.';
              Swal.fire({
                icon: 'error',
                title: 'Error',
                text: errorMessage,
              });
            });
        }
      });
    };
  
    const handleClose = () => {
      setClosing(true);
      setTimeout(() => {
        closePopup();
        setClosing(false); 
      }, 300);
    };

    
  
    return (
      <div className="backdrop">
        <div className={`bid-popup ${closing ? 'closing' : ''}`}>
          <h3>Send bid to {product.name}</h3>
          <input
            type="number"
            value={bidAmount}
            onChange={(e) => setBidAmount(e.target.value)}
            placeholder="Bid Amount"
          />
          <textarea
            value={bidComments}
            onChange={handleCommentsChange}
            placeholder="Any comments"
          />
        <div className="button-container">
          <button onClick={handleSubmit}>Submit Bid</button>
          <button onClick={handleClose}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default BidPopup;
