import React, { useCallback, useEffect, useState } from 'react';
import './Modal.css';

const Modal = ({ product, onClose }) => {
  const [currentMediaIndex, setCurrentMediaIndex] = useState(0);
  const [showArrows, setShowArrows] = useState(false); // State to manage arrow visibility
  const [isClosing, setIsClosing] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1400);

  const videoPlaceholder = '/images/video-placeholder.png';

  const handleClose = useCallback(() => {
    if (!isClosing) {  // Only proceed if not already closing
      setIsClosing(true);
      setTimeout(onClose, 300);  // Adjust time to match your animation duration
    }
  }, [isClosing, onClose]);

  const handleKeyDown = useCallback((event) => {
    if (event.key === 'Escape') {
      handleClose();
    }
  }, [handleClose]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [handleKeyDown]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1400);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleThumbnailClick = (index) => {
    setCurrentMediaIndex(index);
  };

  const toggleArrows = (visible) => {
    setShowArrows(visible);
  };

  const renderMedia = () => {
    const allMedia = [...product.images, ...product.videos];
    const currentMedia = allMedia[currentMediaIndex];

    if (currentMedia && currentMedia.image) {
      return <img src={currentMedia.image} alt={`${product.name} display`} className="main-media" />;
    } else if (currentMedia && currentMedia.video) {
      return (
        <video className="main-media" controls>
          <source src={currentMedia.video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      );
    }
    return null;
  };

  // Conditionally render the modal only if not mobile (window width > 1400px)
  if (isMobile) {
    return null;
  }

  return (
    <div className={`modal-backdrop ${isClosing ? 'closing' : ''}`} onClick={handleClose} aria-modal="true" role="dialog">
      <div className="modal-content9" onClick={e => e.stopPropagation()}>
        <button onClick={handleClose} className='close-modal' aria-label="Close-modal">Close</button>
        <div className="modal-body">
          <div className="image-section">
            <div className="thumbnail-container">
              {[...product.images, ...product.videos].map((media, index) => (
                <img 
                  key={index}
                  src={media.image || videoPlaceholder} 
                  alt={`Thumbnail ${index}`} 
                  className={`thumbnail ${currentMediaIndex === index ? 'active' : ''}`}
                  onClick={() => handleThumbnailClick(index)}
                />
              ))}
            </div>
            {!isMobile && (
            <div className="main-media-container" onMouseEnter={() => toggleArrows(true)} onMouseLeave={() => toggleArrows(false)}>
              {showArrows && <button className="left-arrow" onClick={() => handleThumbnailClick((currentMediaIndex - 1 + product.images.length + product.videos.length) % (product.images.length + product.videos.length))}>&lt;</button>}
              {renderMedia()}
              {showArrows && <button className="right-arrow" onClick={() => handleThumbnailClick((currentMediaIndex + 1) % (product.images.length + product.videos.length))}>&gt;</button>}
            </div>
            )}
          </div>
          <div className="product-details">
            <h2>{product.name}</h2>
            <p>Chest: {product.chest} in</p>
            <p>Full Length: {product.fullLength} in</p>
            <p>Hips: {product.hips} in</p>
            <p>Shoulders: {product.shoulders} in</p>
            <p>Sleeve Length: {product.sleeveLength} in</p>
            <p>Waist: {product.waist} in</p>
            <p>Letout: {product.letout} in</p>
            <p>Year: {product.year} in</p>
            <p>Description: {product.description}</p>
            <p>Price: ${product.price}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
