import React, { useState, useEffect } from 'react';
import './ProductCard.css'; 
import axios from 'axios';
import Modal from './Modal';  
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';


const client = axios.create({
  baseURL: "https://www.refeiscollective.com",
  withCredentials: true,
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFToken',
});
client.interceptors.request.use(function (config) {
  const token = document.cookie.split('; ').find(row => row.startsWith('csrftoken='))?.split('=')[1];
  if (token) {
    config.headers['X-CSRFToken'] = token;
  }
  return config;
});

const ProductCard = ({ product, onBid, isAuthenticated, onLike, isInitiallyLiked, onDelete, onEdit, unlike, showUnlikeButton, currentUser }) => {
  const {
    images = [],
    videos = [],
    name,
    price,
    chest,
    fullLength,
    hips,
    shoulders,
    sleeveLength,
    waist,
    letout,
    brand,
    year
  } = product;

  const [liked, setLiked] = useState(isInitiallyLiked);
  const [likeId, setLikeId] = useState(() => {
    const storedLikeIds = JSON.parse(localStorage.getItem('likeIds') || '{}');
    return storedLikeIds[product.id] || null;
  });

  useEffect(() => {
    const likeIds = JSON.parse(localStorage.getItem('likeIds') || '{}');
    likeIds[product.id] = likeId;
    localStorage.setItem('likeIds', JSON.stringify(likeIds));
  }, [likeId, product.id]);

  const [currentMediaIndex, setCurrentMediaIndex] = useState(0);
  const [isOwner, setIsOwner] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [showArrows, setShowArrows] = useState(false);

  useEffect(() => {
    setLiked(isInitiallyLiked); // Update local state when the prop changes
  }, [isInitiallyLiked]);

  useEffect(() => {
    if (currentUser && product.seller_id === currentUser.user_id) {
      setIsOwner(true);
    } else {
      setIsOwner(false);
    }
  }, [currentUser, product]);


  const handleHeartClick = async (e) => {
    e.stopPropagation();
    if (!isAuthenticated) {
      alert("Please log in to perform this action.");
      return;
    }
    const newLikedState = !liked;
    const url = newLikedState ? '/api/likes/add/' : `/api/likes/remove/${likeId}/`;

    try {
      const response = await client({
        method: newLikedState ? 'post' : 'delete',
        url,
        data: newLikedState ? { product_id: product.id } : null
      });
      if (response.status === 201 && newLikedState) {
        setLiked(true);
        setLikeId(response.data.id);
      } else if (response.status === 204 && !newLikedState) {
        setLiked(false);
        setLikeId(null);
      }
    } catch (error) {
      console.error('Failed to update like status:', error);
      alert(`Failed to update like status: ${error.response?.data?.message || error.message}`);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 800);
    };

    handleResize(); // Check on initial load
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const renderMedia = () => {
    const allMedia = [...images, ...videos];  // Ensure images and videos are arrays
    if (allMedia.length === 0) {
      return <div className="no-media">No media available</div>;
    }
    const currentMedia = allMedia[currentMediaIndex];

    if (currentMedia && currentMedia.image) {
      return <img src={currentMedia.image} alt={`${name} display`} className="product-image" />;
    } else if (currentMedia && currentMedia.video) {
      return (
        <video className="product-video" controls>
          <source src={currentMedia.video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      );
    }
    return null;
  };

  const handlePrev = (e) => {
    e.stopPropagation();
    const allMedia = [...images, ...videos];
    if (allMedia.length > 0) {
      setCurrentMediaIndex((prevIndex) => (prevIndex - 1 + allMedia.length) % allMedia.length);
    }
  };

  const handleNext = (e) => {
    e.stopPropagation();
    const allMedia = [...images, ...videos];
    if (allMedia.length > 0) {
      setCurrentMediaIndex((prevIndex) => (prevIndex + 1) % allMedia.length);
    }
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <div className="product-card"
      onMouseEnter={() => setShowArrows(true)}
      onMouseLeave={() => setShowArrows(false)}
      onClick={() => !isMobile && setModalOpen(true)}>
      <div className="media-container">
        {renderMedia()}
        {showArrows && (
          <>
            <button className="left-arrow1000" onClick={handlePrev}><FaArrowLeft /></button>
            <button className="right-arrow1000" onClick={handleNext}><FaArrowRight /></button>
          </>
        )}
        {isAuthenticated && !isOwner && (
          <svg
            onClick={handleHeartClick}
            style={{ cursor: 'pointer', position: 'absolute', top: '10px', right: '10px' }}
            viewBox="0 0 24 24"
            width="24"
            height="24"
            className="heart-icon"
          >
            <path
              d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"
              fill={liked ? "red" : "none"}
              stroke="black"
            />
          </svg>
        )}
      </div>
      <div className="product-info">
        <h3 className="product-name">{name}</h3>
        <ul className="product-measurements">
          <li>Chest: {chest} in</li>
          <li>Full Length: {fullLength} in</li>
          <li>Hips: {hips} in</li>
          <li>Shoulders: {shoulders} in</li>
          <li>Sleeve Length: {sleeveLength} in</li>
          <li>Waist: {waist} in</li>
          <li>Letout: {letout} in</li>
          <li>Designer: {brand}</li>
          <li>Year: {year}</li>
        </ul>
        <p className="product-price">${price}</p>
        {isAuthenticated && !isOwner && (
          <button className="bid-btn" onClick={(e) => { e.stopPropagation(); onBid(product); }}>Bid</button>
        )}
      </div>
      {onEdit && (
        <button className="edit-btn" onClick={(e) => {
          e.stopPropagation();  // Prevents the card's main click functionality
          onEdit();
        }}>
          Edit
        </button>
      )}
      {onDelete && (
        <button className="delete-btn" onClick={(e) => {
          e.stopPropagation();  // Prevents the card's main click functionality
          onDelete(product.id);
        }}>
          Delete
        </button>
      )}
      {showUnlikeButton && (
        <button className="unlike-btn" onClick={(e) => {
          e.stopPropagation();  
          unlike();
        }}>
          Unlike
        </button>
      )}
      {modalOpen && <Modal product={product} onClose={toggleModal} />}
    </div>
  );
};

export default ProductCard;
