import React from 'react';
import './MessageModal.css';

const MessageModal = ({ message, isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="message-modal-backdrop">
      <div className="message-modal">
        <p>{message}</p>
        <button className='close-button-100' onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default MessageModal;
