import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ProductCard from './ProductCard';
import BidPopup from './BidPopup';
import Pagination from './Pagination';  // Assuming you have a Pagination component

const client = axios.create({
  baseURL: "https://www.refeiscollective.com",
  withCredentials: true,
});

client.interceptors.request.use(function (config) {
  const token = document.cookie.split('; ').find(row => row.startsWith('csrftoken='))
    ?.split('=')[1];
  config.headers['X-CSRFToken'] = token;
  return config;
});

const ProductList = ({ isAuthenticated, category, searchTerm, sort, priceRange, chestRange, fullLengthRange, hipsRange, 
  shoulderRange, sleeveLengthRange, waistRange, letoutRange, selectedDesigner, selectedYear }) => {
  const [products, setProducts] = useState([]);
  const [isBidding, setIsBidding] = useState(false);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [bidAmount, setBidAmount] = useState('');
  const [bidComments, setBidComments] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        const userResponse = await client.get("/api/user");
        if (userResponse.status === 200 && userResponse.data.user) {
          setCurrentUser({
            user_id: userResponse.data.user.user_id,
            username: userResponse.data.user.username,
            isAuthenticated: true
          });
        } else {
          setCurrentUser(null);
        }
      } catch (error) {
        setCurrentUser(null);
      }
    };

    fetchCurrentUser();
  }, []);

  useEffect(() => {
    const fetchProductsAndLikes = async () => {
      try {
        let url = '/api/products';
        const params = [];
        if (category) params.push(`category=${category}`);
        if (searchTerm) params.push(`search=${searchTerm}`);
        if (sort) params.push(`sort=${sort}`);
        if (priceRange) {
          params.push(`min_price=${priceRange.min}`);
          params.push(`max_price=${priceRange.max}`);
        }
        if (chestRange) {
          params.push(`min_chest=${chestRange.min}`);
          params.push(`max_chest=${chestRange.max}`);
        }
        if (fullLengthRange) {
          params.push(`min_fullLength=${fullLengthRange.min}`);
          params.push(`max_fullLength=${fullLengthRange.max}`);
        }
        if (hipsRange) {
          params.push(`min_hips=${hipsRange.min}`);
          params.push(`max_hips=${hipsRange.max}`);
        }
        if (shoulderRange) {
          params.push(`min_shoulders=${shoulderRange.min}`);
          params.push(`max_shoulders=${shoulderRange.max}`);
        }
        if (sleeveLengthRange) {
          params.push(`min_sleeveLength=${sleeveLengthRange.min}`);
          params.push(`max_sleeveLength=${sleeveLengthRange.max}`);
        }
        if (waistRange) {
          params.push(`min_waist=${waistRange.min}`);
          params.push(`max_waist=${waistRange.max}`);
        }
        if (letoutRange) {
          params.push(`min_letout=${letoutRange.min}`);
          params.push(`max_letout=${letoutRange.max}`);
        }
        if (selectedDesigner) params.push(`designer=${selectedDesigner}`);
        if (selectedYear) params.push(`year=${selectedYear}`);
        params.push(`page=${currentPage}`);

        if (params.length) url += `?${params.join('&')}`;

        const productsResponse = await client.get(url);
        setProducts(productsResponse.data.results);
        setTotalPages(Math.ceil(productsResponse.data.count / 16)); 
        setLoading(false);
      } catch (error) {
        console.error('Error fetching products:', error);
        setLoading(false);
      }
    };

    fetchProductsAndLikes();
  }, [category, searchTerm, sort, priceRange, chestRange, fullLengthRange, hipsRange, shoulderRange, sleeveLengthRange, 
    waistRange, letoutRange, selectedDesigner, selectedYear, currentPage]); // Add currentPage to dependencies

  const onBid = (product) => {
    setCurrentProduct(product);
    setIsBidding(true);
  };

  const submitBid = async () => {
    if (!bidAmount || !bidComments) {
      alert("Please enter a bid amount and comments.");
      return;
    }

    const bidData = {
      amount: bidAmount,
      description: bidComments,
      product_id: currentProduct.id
    };

    try {
      const response = await client.post('/api/bids/', bidData);
      if (response.status === 201) {
        setIsBidding(false);
        setBidAmount('');
        setBidComments('');
      } else {
        alert(`Failed to submit bid: Status code ${response.status}`);
      }
    } catch (error) {
      console.error('Error submitting bid:', error);
    }
  };

  const handleLike = async (productId) => {
    if (!isAuthenticated) {
      alert("Please log in to like products.");
      return;
    }
    try {
      const response = await client.post('/api/likes/', { product_id: productId });
      if (response.status === 201) {
        alert('Product liked successfully');
      } else {
        console.error('Unexpected status code:', response.status);
      }
    } catch (error) {
      console.error('Failed to like product', error.response ? error.response.data : "No response data");
      alert('Failed to like product');
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="products-container">
      <div className="products-list">
        {products.map((product) => (
          <ProductCard 
            key={product.id}
            product={product}
            onBid={onBid}
            onLike={handleLike}
            isAuthenticated={isAuthenticated}
            isInitiallyLiked={product.is_liked}
            currentUser={currentUser}
          />
        ))}
      </div>
      {isBidding && (
        <BidPopup
          product={currentProduct}
          bidAmount={bidAmount}
          setBidAmount={setBidAmount}
          bidComments={bidComments}
          setBidComments={setBidComments}
          submitBid={submitBid}
          closePopup={() => setIsBidding(false)}
        />
      )}
      <div className="pagination-wrapper">
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={setCurrentPage}
        />
      </div>
    </div>
  );
};

export default ProductList;
