import React, { useState, useEffect } from 'react';
import './UpdateProductModal.css';
import Swal from 'sweetalert2';

const badWords = ["fuck", "bitch", "shit", "ass","hell","fag","faggot","nigga","nigger","dick","pussy","damn","fugly","poop","dike","arse","asshole",
                    "tity","slut","whore","titties","slutty","hooker","cunt","bullshit","crap","bastard","nigro","nigra","niggas","jesus christ","balls",
                    "cum","piss","niggaz","nig","jizz","skank","dildo","dildos","niglet","penis","vagina","chink","ching chong","ape","anus","rape",
                    "sex","sexual","sexy","fat","fetish","gay","lesbian","homo","homosexual","homosexuality","homosex","obey","sexism","sexist",
                    "obese","ugly","ugliest","uglyest","uglyass","uglyasses","uglyboi","uglyboy","uglyboys","uglygirl","uglygirls","uglygirly","blowjob",
                    "porn","semen","kill","murder","piss","sexualintercourse","bimbo","crack","cocaine","drugs","pornography","weed","drunk","shoot",
                    "marijuana","suck","nuts","nutts","sucknut"
]; 

function containsBadWord(input) {
    return badWords.some(badWord => input.toLowerCase().includes(badWord));
  }
  
  const UpdateProductModal = ({ product, onClose, onUpdate }) => {
      const [removeMediaIds, setRemoveMediaIds] = useState([]);
      const [formData, setFormData] = useState({
          name: product.name,
          description: product.description,
          price: product.price,
          category: product.category,
          chest: product.chest,
          fullLength: product.fullLength,
          hips: product.hips,
          shoulders: product.shoulders,
          sleeveLength: product.sleeveLength,
          waist: product.waist,
          letout: product.letout,
          brand: product.brand,
          year: product.year,
          uploaded_images: product.images || [], // Store image objects
          uploaded_videos: product.videos || [] // Store video objects
      });
  
      useEffect(() => {
          setFormData({
              name: product.name,
              description: product.description,
              price: product.price,
              category: product.category,
              chest: product.chest,
              fullLength: product.fullLength,
              hips: product.hips,
              shoulders: product.shoulders,
              sleeveLength: product.sleeveLength,
              waist: product.waist,
              letout: product.letout,
              brand: product.brand,
              year: product.year,
              uploaded_images: product.images || [],
              uploaded_videos: product.videos || []
          });
      }, [product]);
  
      const handleInputChange = (e) => {
          const { name, value } = e.target;
          if (name === 'name' || name === 'description' || name === 'brand') {
              if (containsBadWord(value)) {
                  Swal.fire({
                      icon: 'error',
                      title: 'Invalid Input',
                      text: 'Your input contains inappropriate content. Please remove it.',
                  });
                  return;
              }
          }
          setFormData({ ...formData, [name]: value });
      };
  
      const handleRemoveMedia = (mediaId, type) => {
          setRemoveMediaIds(prev => [...prev, { id: mediaId, type }]);
          setFormData(prev => ({
              ...prev,
              uploaded_images: type === 'image' ? prev.uploaded_images.filter(media => media.id !== mediaId) : prev.uploaded_images,
              uploaded_videos: type === 'video' ? prev.uploaded_videos.filter(media => media.id !== mediaId) : prev.uploaded_videos
          }));
      };
  
      const handleSubmit = (e) => {
          e.preventDefault();
          const updateData = new FormData();
  
          Object.keys(formData).forEach(key => {
              if (key === 'uploaded_images' || key === 'uploaded_videos') {
                  formData[key].forEach(file => {
                      if (file instanceof File) {
                          updateData.append(key, file);
                      }
                  });
              } else {
                  updateData.append(key, formData[key]);
              }
          });
  
          removeMediaIds.forEach(media => {
              updateData.append(media.type === 'image' ? 'remove_images' : 'remove_videos', media.id);
          });
  
          onUpdate(product.id, updateData);
      };
  
      const handleMediaChange = (e) => {
          const { name, files } = e.target;
          setFormData({
              ...formData,
              [name]: [...formData[name], ...Array.from(files)]
          });
      };
  
      return (
          <div className="modal-backdrop_10">
              <div className="modal-content_10">
                  <form onSubmit={handleSubmit}>
                      <label>Name:</label>
                      <input
                          type="text"
                          name="name"
                          value={formData.name}
                          onChange={handleInputChange}
                      />
                      <label>Description:</label>
                      <textarea
                          name="description"
                          value={formData.description}
                          onChange={handleInputChange}
                      />
                      <label>Price:</label>
                      <input
                          type="number"
                          name="price"
                          value={formData.price}
                          onChange={(e) => setFormData({ ...formData, price: e.target.value })}
                      />
                      <label>Category:</label>
                      <input
                          type="text"
                          name="category"
                          value={formData.category}
                          onChange={(e) => setFormData({ ...formData, category: e.target.value })}
                      />
                      <label>Chest:</label>
                      <input
                          type="number"
                          name="chest"
                          value={formData.chest}
                          onChange={(e) => setFormData({ ...formData, chest: e.target.value })}
                      />
                      <label>Full Length:</label>
                      <input
                          type="number"
                          name="fullLength"
                          value={formData.fullLength}
                          onChange={(e) => setFormData({ ...formData, fullLength: e.target.value })}
                      />
                      <label>Hips:</label>
                      <input
                          type="number"
                          name="hips"
                          value={formData.hips}
                          onChange={(e) => setFormData({ ...formData, hips: e.target.value })}
                      />
                      <label>Shoulders:</label>
                      <input
                          type="number"
                          name="shoulders"
                          value={formData.shoulders}
                          onChange={(e) => setFormData({ ...formData, shoulders: e.target.value })}
                      />
                      <label>Sleeve Length:</label>
                      <input
                          type="number"
                          name="sleeveLength"
                          value={formData.sleeveLength}
                          onChange={(e) => setFormData({ ...formData, sleeveLength: e.target.value })}
                      />
                      <label>Waist:</label>
                      <input
                          type="number"
                          name="waist"
                          value={formData.waist}
                          onChange={(e) => setFormData({ ...formData, waist: e.target.value })}
                      />
                      <label>Letout:</label>
                      <input
                          type="number"
                          name="letout"
                          value={formData.letout}
                          onChange={(e) => setFormData({ ...formData, letout: e.target.value })}
                      />
                      <label>Designer:</label>
                      <input
                          type="text"
                          name="brand"
                          value={formData.brand}
                          onChange={handleInputChange}
                      />
                      <label>Year:</label>
                      <input
                          type="number"
                          name="year"
                          value={formData.year}
                          onChange={(e) => setFormData({ ...formData, year: e.target.value })}
                      />
  
                      <label>Images:</label>
                      <input
                          type="file"
                          name="uploaded_images"
                          multiple
                          onChange={handleMediaChange}
                      />
                      <label>Videos:</label>
                      <input
                          type="file"
                          name="uploaded_videos"
                          multiple
                          accept="video/*"
                          onChange={handleMediaChange}
                      />
  
                      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                          {formData.uploaded_images.map((media, index) => (
                              <div key={index} style={{ position: 'relative', margin: '5px' }}>
                                  <img
                                      src={media instanceof File ? URL.createObjectURL(media) : media.image}
                                      alt="Preview"
                                      style={{ width: '100px', height: 'auto' }}
                                      onLoad={() => media instanceof File && URL.revokeObjectURL(media)}
                                  />
                                  {media.id && (
                                      <button
                                          style={{ position: 'absolute', top: 0, right: 0 }}
                                          onClick={() => handleRemoveMedia(media.id, 'image')}
                                      >
                                          X
                                      </button>
                                  )}
                              </div>
                          ))}
                          {formData.uploaded_videos.map((media, index) => (
                              <div key={index} style={{ position: 'relative', margin: '5px' }}>
                                  <img
                                      src={media instanceof File ? URL.createObjectURL(media) : '/images/video-placeholder.png'}
                                      alt="Video Preview"
                                      style={{ width: '100px', height: 'auto' }}
                                      onLoad={() => media instanceof File && URL.revokeObjectURL(media)}
                                  />
                                  {media.id && (
                                      <button
                                          style={{ position: 'absolute', top: 0, right: 0 }}
                                          onClick={() => handleRemoveMedia(media.id, 'video')}
                                      >
                                          X
                                      </button>
                                  )}
                              </div>
                          ))}
                      </div>
                      <button type="submit">Update</button>
                      <button onClick={onClose}>Cancel</button>
                  </form>
              </div>
          </div>
      );
  };
  
  export default UpdateProductModal;
