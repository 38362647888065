// import React, { useState, useEffect, useRef } from 'react';
// import { useNavigate, Link, useLocation } from 'react-router-dom';
// import axios from 'axios';
// import './Header.css';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faUser, faHeart, faGavel, faSignInAlt, faUpload, faBell, faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
// import { debounce } from 'lodash';

// const client = axios.create({
//   baseURL: "http://127.0.0.1:8000",
//   withCredentials: true,
//   xsrfCookieName: 'csrftoken',
//   xsrfHeaderName: 'X-CSRFToken',
// });

// client.interceptors.request.use(function (config) {
//   const token = document.cookie.split('; ').find(row => row.startsWith('csrftoken='))
//       ?.split('=')[1];
//   config.headers['X-CSRFToken'] = token;
//   return config;
// });

// function Header({ isAuthenticated, onLogout }) {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const [username, setUsername] = useState(null);
//   const [notifications, setNotifications] = useState([]);
//   const [showNotifications, setShowNotifications] = useState(false);
//   const [showUserMenu, setShowUserMenu] = useState(false);
//   const [isMenuOpen, setIsMenuOpen] = useState(false);

//   const ws = useRef(null);
//   const reconnectAttempts = useRef(0);

//   useEffect(() => {
//     const connectWebSocket = () => {
//       if (ws.current) {
//         ws.current.close();
//       }
//       ws.current = new WebSocket(`ws://127.0.0.1:8000/ws/notifications/`);
//       ws.current.onopen = () => {
//         console.log("Connected to WebSocket");
//         reconnectAttempts.current = 0; 
//       };
//       ws.current.onclose = (event) => {
//         console.error('WebSocket Disconnected', event);
//         if (!event.wasClean) {
//           setTimeout(() => {
//             reconnectAttempts.current++;
//             connectWebSocket();
//           }, Math.min(10000, 1000 * 2 ** reconnectAttempts.current)); 
//         }
//       };
//       ws.current.onerror = (error) => {
//         console.error('WebSocket Error', error);
//         ws.current.close();
//       };
//       ws.current.onmessage = (event) => {
//         console.log("Raw WebSocket message:", event.data);
//         const data = JSON.parse(event.data);
//         console.log("Parsed WebSocket data:", data);

//         if (data.recipient === username && data.notification_type) {
//           setNotifications(prev => {
//             const updatedNotifications = Array.isArray(prev) ? [...prev, {
//               message: data.message,
//               id: data.id || prev.length + 1,
//               read: data.read,
//               timestamp: data.timestamp,
//               type: data.notification_type
//             }] : [];
//             console.log("Updated notifications state:", updatedNotifications);
//             return updatedNotifications;
//           });
//         }
//       };
//     };

//     if (isAuthenticated) {
//       connectWebSocket();
//     }

//     return () => {
//       if (ws.current) {
//         ws.current.close();
//         ws.current = null;
//       }
//     };
//   }, [isAuthenticated, username]);

//   useEffect(() => {
//     const fetchNotifications = async () => {
//       try {
//         const response = await client.get('/api/notifications/');
//         if (response.status === 200) {
//           const fetchedNotifications = Array.isArray(response.data) ? response.data : [];
//           setNotifications(fetchedNotifications);
//         }
//       } catch (error) {
//         console.error('Error fetching notifications:', error);
//       }
//     };

//     if (isAuthenticated) {
//       fetchNotifications();
//     }
//   }, [isAuthenticated]);

//   useEffect(() => {
//     setIsMenuOpen(false);
//     setShowUserMenu(false); 
//   }, [isAuthenticated]);

//   useEffect(() => {
//     const fetchUsername = async () => {
//       try {
//         const userResponse = await client.get("/api/user");
//         if (userResponse.status === 200 && userResponse.data.user) {
//           setUsername(userResponse.data.user.username);
//         }
//       } catch (error) {
//         console.error('Error fetching username:', error);
//       }
//     };
//     if (isAuthenticated) {
//       fetchUsername();
//     } else {
//       setUsername(null);
//     }
//   }, [isAuthenticated]);

//   useEffect(() => {
//     console.log("Notifications state updated:", notifications);
//     if (!Array.isArray(notifications)) {
//       console.error("Notifications is not an array:", notifications);
//       setNotifications([]);
//     }
//   }, [notifications]);

  

//   const handleLogout = async () => {
//     try {
//       const response = await client.post("/api/logout", {}, {
//         headers: {
//           'Content-Type': 'application/json'
//         }
//       });
//       if (response.status === 200) {
//         document.cookie = "sessionid=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT";
//         onLogout();
//         navigate('/login');
//       }
//     } catch (error) {
//       console.error('Logout failed:', error);
//     }
//   };

//   const goToUploadForm = () => {
//     navigate('/upload-product');
//   };

//   const toggleNotifications = () => {
//     setShowNotifications((prev) => !prev);
//     if (showUserMenu) {
//       setShowUserMenu(false);
//     }
//   };

//   const handleNotificationClick = debounce(async (notification) => {
//     try {
//       await client.post(`/api/notifications/${notification.id}/mark_as_read/`);
//       console.log('Notification marked as read');
//       setNotifications(notifications.map(n =>
//         n.id === notification.id ? { ...n, read: true } : n
//       ));
//     } catch (error) {
//       console.error('Error marking notification as read', error);
//     }
//   }, 300);

//   const isLoginPage = location.pathname === '/login' || location.pathname === '/register';
//   const unreadNotificationCount = Array.isArray(notifications) ? notifications.filter(notification => !notification.read).length : 0;

//   const toggleUserMenu = () => {
//     setShowUserMenu((prev) => !prev);
//     if (showNotifications) {
//       setShowNotifications(false);
//     }
//   };

//   const handleMenuItemClick = () => {
//     setIsMenuOpen(false);
//   };

//   const toggleNavbar = () => {
//     if (isMenuOpen) {
//       document.querySelector('.navbar-center').classList.add('closing');
//       setTimeout(() => {
//         setIsMenuOpen(false);
//         document.querySelector('.navbar-center').classList.remove('closing');
//       }, 500); 
//     } else {
//       setIsMenuOpen(true);
//     }
//   };

//   const clearAllNotifications = async () => {
//     try {
//         const response = await client.post('/api/clear-notifications/');
//         if (response.status === 200) {
//             setNotifications([]); 
//         }
//     } catch (error) {
//         console.error('Error clearing notifications:', error);
//     }
//   };

//   return (
//     <nav className="navbar">
//       <div className="navbar-brand">
//         <Link to="/">
//           <img src="/C.svg" alt="Refeis" />
//         </Link>
//       </div>
//       <div className={`navbar-center ${isAuthenticated ? 'logged-in' : ''} ${isMenuOpen ? 'open' : ''}`}>
//         <ul className={`navbar-nav ${isAuthenticated ? 'auth-nav' : 'guest-nav'}`} onClick={handleMenuItemClick}>
//           {!isLoginPage && (
//             <>
//               <li><Link to="/" className={`nav-link ${location.pathname === '/' ? 'active' : ''}`}>New Arrivals</Link></li>
//               <li><Link to="/women" className={`nav-link ${location.pathname === '/women' ? 'active' : ''}`}>Women</Link></li>
//               <li><Link to="/men" className={`nav-link ${location.pathname === '/men' ? 'active' : ''}`}>Men</Link></li>
//               {isAuthenticated && (
//                 <>
//                   <li><Link to="/my-likes" className={`nav-link ${location.pathname === '/my-likes' ? 'active' : ''}`}><FontAwesomeIcon icon={faHeart} /> Likes</Link></li>
//                   <li><Link to="/my-bids" className={`nav-link ${location.pathname === '/my-bids' ? 'active' : ''}`}><FontAwesomeIcon icon={faGavel} /> Bids</Link></li>
//                   <li><button onClick={goToUploadForm} className="upload-button"><FontAwesomeIcon icon={faUpload} /> Upload Product</button></li>
//                 </>
//               )}
//             </>
//           )}
//           {!isAuthenticated && (
//             <li><Link to="/login" className={`nav-link ${location.pathname === '/login' ? 'active' : ''}`}><FontAwesomeIcon icon={faSignInAlt} /> Login/Register</Link></li>
//           )}
//         </ul>
//       </div>
//       <div className="navbar-right">
//         {isAuthenticated && (
//           <>
//             <div className="notification-icon" onClick={toggleNotifications}>
//               <FontAwesomeIcon icon={faBell} />
//               {unreadNotificationCount > 0 && (
//                 <span className="notification-count">{unreadNotificationCount}</span>
//               )}
//             </div>
//             <div className={`notification-dropdown ${showNotifications ? 'show' : ''}`}>
//               {Array.isArray(notifications) && notifications.length > 0 ? (
//                 <>
//                   <div className="notification-list">
//                     {notifications.map((notification, index) => (
//                       <div
//                         key={index}
//                         className={`notification-item ${notification.read ? 'read' : 'unread'}`}
//                         onClick={() => handleNotificationClick(notification)}
//                       >
//                         {notification.message}
//                       </div>
//                     ))}
//                   </div>
//                   <button className="clear-notifications" onClick={clearAllNotifications}>Clear All</button>
//                 </>
//               ) : (
//                 <div className="notification-item">You have no notifications</div>
//               )}
//             </div>
//             <div className="user-menu-icon" onClick={toggleUserMenu}>
//               <FontAwesomeIcon icon={faUser} />
//             </div>
//             <div className={`user-menu-dropdown ${showUserMenu ? 'show' : ''}`}>
//               <Link to="/profile" className="dropdown-item100">Listed Products</Link>
//               <Link to="/myprofile" className="dropdown-item100">{username}</Link>
//               <button40 onClick={handleLogout} className="dropdown-item100">Logout</button40>
//             </div>
//           </>
//         )}
//         <button className="navbar-toggle" onClick={toggleNavbar}>
//           <FontAwesomeIcon icon={isMenuOpen ? faTimes : faBars} />
//         </button>
//       </div>
//     </nav>
//   );
// }

// export default Header;
















import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import './Header.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faHeart, faGavel, faSignInAlt, faUpload, faBell, faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { debounce } from 'lodash';

const client = axios.create({
  baseURL: "https://www.refeiscollective.com",
  withCredentials: true,
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFToken',
});

client.interceptors.request.use(function (config) {
  const token = document.cookie.split('; ').find(row => row.startsWith('csrftoken='))
      ?.split('=')[1];
  config.headers['X-CSRFToken'] = token;
  return config;
});

function Header({ isAuthenticated, onLogout }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [username, setUsername] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(false);
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const notificationRef = useRef(null);
  const userMenuRef = useRef(null);

  const ws = useRef(null);
  const reconnectAttempts = useRef(0);

  useEffect(() => {
    const connectWebSocket = () => {
      if (ws.current) {
        ws.current.close();
      }
      ws.current = new WebSocket(`wss://www.refeiscollective.com/ws/notifications/`);
      ws.current.onopen = () => {
        console.log("Connected to WebSocket");
        reconnectAttempts.current = 0; 
      };
      ws.current.onclose = (event) => {
        console.error('WebSocket Disconnected', event);
        if (!event.wasClean) {
          setTimeout(() => {
            reconnectAttempts.current++;
            connectWebSocket();
          }, Math.min(10000, 1000 * 2 ** reconnectAttempts.current)); 
        }
      };
      ws.current.onerror = (error) => {
        console.error('WebSocket Error', error);
        ws.current.close();
      };
      ws.current.onmessage = (event) => {
        console.log("Raw WebSocket message:", event.data);
        const data = JSON.parse(event.data);
        console.log("Parsed WebSocket data:", data);

        if (data.recipient === username && data.notification_type) {
          setNotifications(prev => {
            const updatedNotifications = Array.isArray(prev) ? [...prev, {
              message: data.message,
              id: data.id || prev.length + 1,
              read: data.read,
              timestamp: data.timestamp,
              type: data.notification_type
            }] : [];
            console.log("Updated notifications state:", updatedNotifications);
            return updatedNotifications;
          });
        }
      };
    };

    if (isAuthenticated) {
      connectWebSocket();
    }

    return () => {
      if (ws.current) {
        ws.current.close();
        ws.current = null;
      }
    };
  }, [isAuthenticated, username]);


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (notificationRef.current && !notificationRef.current.contains(event.target)) {
        setShowNotifications(false);
      }
      if (userMenuRef.current && !userMenuRef.current.contains(event.target)) {
        setShowUserMenu(false);
      }
    };

    const handleTabVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        setShowNotifications(false);
        setShowUserMenu(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    document.addEventListener('visibilitychange', handleTabVisibilityChange);

    return () => {
      document.removeEventListener('click', handleClickOutside);
      document.removeEventListener('visibilitychange', handleTabVisibilityChange);
    };
  }, []);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await client.get('/api/notifications/');
        if (response.status === 200) {
          const fetchedNotifications = Array.isArray(response.data) ? response.data : [];
          setNotifications(fetchedNotifications);
        }
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    };

    if (isAuthenticated) {
      fetchNotifications();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    setIsMenuOpen(false);
    setShowUserMenu(false); 
  }, [isAuthenticated]);

  useEffect(() => {
    const fetchUsername = async () => {
      try {
        const userResponse = await client.get("/api/user");
        if (userResponse.status === 200 && userResponse.data.user) {
          setUsername(userResponse.data.user.username);
        }
      } catch (error) {
        console.error('Error fetching username:', error);
      }
    };
    if (isAuthenticated) {
      fetchUsername();
    } else {
      setUsername(null);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    console.log("Notifications state updated:", notifications);
    if (!Array.isArray(notifications)) {
      console.error("Notifications is not an array:", notifications);
      setNotifications([]);
    }
  }, [notifications]);

  

  const handleLogout = async () => {
    try {
      const response = await client.post("/api/logout", {}, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (response.status === 200) {
        document.cookie = "sessionid=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT";
        onLogout();
        navigate('/login');
      }
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const goToUploadForm = () => {
    navigate('/upload-product');
  };

  const toggleNotifications = () => {
    setShowNotifications((prev) => !prev);
    if (showUserMenu) {
      setShowUserMenu(false);
    }
  };

  const handleNotificationClick = debounce(async (notification) => {
    try {
      await client.post(`/api/notifications/${notification.id}/mark_as_read/`);
      console.log('Notification marked as read');
      setNotifications(notifications.map(n =>
        n.id === notification.id ? { ...n, read: true } : n
      ));
    } catch (error) {
      console.error('Error marking notification as read', error);
    }
  }, 300);

  const isLoginPage = location.pathname === '/login' || location.pathname === '/register';
  const unreadNotificationCount = Array.isArray(notifications) ? notifications.filter(notification => !notification.read).length : 0;

  const toggleUserMenu = () => {
    setShowUserMenu((prev) => !prev);
    if (showNotifications) {
      setShowNotifications(false);
    }
  };

  const handleMenuItemClick = () => {
    setIsMenuOpen(false);
  };

  const toggleNavbar = () => {
    if (isMenuOpen) {
      document.querySelector('.navbar-center').classList.add('closing');
      setTimeout(() => {
        setIsMenuOpen(false);
        document.querySelector('.navbar-center').classList.remove('closing');
      }, 500); 
    } else {
      setIsMenuOpen(true);
    }
  };

  const clearAllNotifications = async () => {
    try {
        const response = await client.post('/api/clear-notifications/');
        if (response.status === 200) {
            setNotifications([]); 
        }
    } catch (error) {
        console.error('Error clearing notifications:', error);
    }
  };

  return (
    <nav className="navbar">
      <div className="navbar-brand">
        <Link to="/">
          <img src="/C.svg" alt="Refeis" />
        </Link>
      </div>
      <div className={`navbar-center ${isAuthenticated ? 'logged-in' : ''} ${isMenuOpen ? 'open' : ''}`}>
        <ul className={`navbar-nav ${isAuthenticated ? 'auth-nav' : 'guest-nav'}`} onClick={handleMenuItemClick}>
          {!isLoginPage && (
            <>
              <li><Link to="/" className={`nav-link ${location.pathname === '/' ? 'active' : ''}`}>New Arrivals</Link></li>
              <li><Link to="/women" className={`nav-link ${location.pathname === '/women' ? 'active' : ''}`}>Women</Link></li>
              <li><Link to="/men" className={`nav-link ${location.pathname === '/men' ? 'active' : ''}`}>Men</Link></li>
              {isAuthenticated && (
                <>
                  <li><Link to="/my-likes" className={`nav-link ${location.pathname === '/my-likes' ? 'active' : ''}`}><FontAwesomeIcon icon={faHeart} /> Likes</Link></li>
                  <li><Link to="/my-bids" className={`nav-link ${location.pathname === '/my-bids' ? 'active' : ''}`}><FontAwesomeIcon icon={faGavel} /> Bids</Link></li>
                  <li><button onClick={goToUploadForm} className="upload-button"><FontAwesomeIcon icon={faUpload} /> Upload Product</button></li>
                </>
              )}
            </>
          )}
          {!isAuthenticated && (
            <li><Link to="/login" className={`nav-link ${location.pathname === '/login' ? 'active' : ''}`}><FontAwesomeIcon icon={faSignInAlt} /> Login/Register</Link></li>
          )}
        </ul>
      </div>
      <div className="navbar-right">
        {isAuthenticated && (
          <>
            <div className="notification-icon" onClick={toggleNotifications} ref={notificationRef} >
              <FontAwesomeIcon icon={faBell} />
              {unreadNotificationCount > 0 && (
                <span className="notification-count">{unreadNotificationCount}</span>
              )}
            </div>
            <div className={`notification-dropdown ${showNotifications ? 'show' : ''}`}>
              {Array.isArray(notifications) && notifications.length > 0 ? (
                <>
                  <div className="notification-list">
                    {notifications.map((notification, index) => (
                      <div
                        key={index}
                        className={`notification-item ${notification.read ? 'read' : 'unread'}`}
                        onClick={() => handleNotificationClick(notification)}
                      >
                        {notification.message}
                      </div>
                    ))}
                  </div>
                  <button className="clear-notifications" onClick={clearAllNotifications}>Clear All</button>
                </>
              ) : (
                <div className="notification-item">You have no notifications</div>
              )}
            </div>
            <div className="user-menu-icon" onClick={toggleUserMenu} ref={userMenuRef}>
              <FontAwesomeIcon icon={faUser} />
            </div>
            <div className={`user-menu-dropdown ${showUserMenu ? 'show' : ''}`}>
              <Link to="/profile" className="dropdown-item100">Listed Products</Link>
              <Link to="/myprofile" className="dropdown-item100">{username}</Link>
              <button40 onClick={handleLogout} className="dropdown-item100">Logout</button40>
            </div>
          </>
        )}
        <button className="navbar-toggle" onClick={toggleNavbar}>
          <FontAwesomeIcon icon={isMenuOpen ? faTimes : faBars} />
        </button>
      </div>
    </nav>
  );
}

export default Header;
