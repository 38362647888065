import React from 'react';
import './NewArrivals.css';

const NewArrivals = () => {
  const scrollToProducts = () => {
    const productList = document.getElementById('product-list');
    if (productList) {
      productList.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="new-arrivals">
      <div className="left-content">
        <div className="text-container"> {/* Additional wrapper for alignment */}
          <h1>Refeis</h1>
          <h2>Collective</h2>
          <button onClick={scrollToProducts} className="shop-now-button">
            Shop Now
          </button>
        </div>
      </div>
      <div className="right-content">
        {/* Background image will be set via CSS */}
      </div>
    </div>
  );
};

export default NewArrivals;
