import React from 'react';
import './Women.css'; 

const Women = () => {
  const scrollToProducts = () => {
    const productList = document.getElementById('product-list');
    if (productList) {
      productList.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="women">
      <div className="left-content_1"> 
        {/* Background image will be set via CSS */}
      </div>
      <div className="right-content_1"> 
        <div className="text-container_1">
          <h1>Refeis</h1>
          <h2>Collective</h2>
          <button onClick={scrollToProducts} className="shop-now-button_1">
            Shop Women
          </button>
        </div>
      </div>
    </div>
  );
};

export default Women;
