import React from 'react';
import './FilterBar.css';
import searchIcon from './search-icon.svg';

const FilterBar = ({ searchTerm, handleSearchChange, sort, handleSortChange}) => {
  return (
    <div className="filter-bar100">
      <div className="search-container100">
        <input
          type="text"
          placeholder="Search new arrivals..."
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <img src={searchIcon} className="search-icon100" alt="Search" />
        </div>
      <select className="filter-select100" value={sort} onChange={handleSortChange}>
        <option value="">Price Sort</option>
        <option value="price_low_high">Price: Low to High</option>
        <option value="price_high_low">Price: High to Low</option>
      </select>
    </div>
  );
};

export default FilterBar;