import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import './LoginPage.css';

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = true;

const client = axios.create({
  baseURL: "https://www.refeiscollective.com",
});

function Login({ onLogin }) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    function submitLogin(e) {
      e.preventDefault();
      client.post("/api/login", { email, password })
        .then(function(res) {
          onLogin(true);
          navigate('/');
        })
        .catch(function(error) {
          console.error("Login failed:", error);
          setError('Failed to login. Please check your credentials.');
        });
    }

    return (
      <div className="login-container">
        {error && <div className="alert alert-danger">{error}</div>}
        <form className="login-form" onSubmit={submitLogin}>
          <div className="form-group">
            <label>Email address</label>
            <input
              type="email"
              className="form-control"
              placeholder="Enter email"
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>Password</label>
            <div className="password-container">
              <input
                type={showPassword ? "text" : "password"}
                className="form-control"
                placeholder="Enter password"
                value={password}
                onChange={e => setPassword(e.target.value)}
              />
              {password && (
                <small
                  onClick={() => setShowPassword(!showPassword)}
                  style={{ cursor: 'pointer' }}
                  className="show-hide-password"
                >
                  {showPassword ? "Hide" : "Show"}
                </small>
              )}
            </div>
          </div>
          <button className="submit-btn100" type="submit">Log in</button>
          <div className="register-link">
            New User? <Link to="/register">Register</Link>
          </div>
          <div className="forgot-password-link">
            Forgot Password? <Link to="/forgot-password">Reset here</Link>
          </div>
        </form>
      </div>
    );
  };
  
  export default Login;
